export const groupData = {
    // table 属性
    tableList : [
        {name:'证券代码',text: 'marketSID'},
        {name:'证券名称',text: 'name'},
        {name:'持仓数量',text: 'amount'},
        {name:'买卖方向',text: 'direction'},
        {name:'可用数量',text: 'availableAmount'},
        {name:'冻结数量',text: 'frozenAmount'},
        {name:'成本价',text: 'averageOpenPrice'},
        {name:'市价',text: 'currentPrice'},
        {name:'市值',text: 'marketValue'},
        {name:'浮动盈亏',text: 'floatingPL'},
        // {name:'盈亏比例',text: 'profitAndLossRatio'},
        {name:'交易市场',text: 'marketPlace'},
    ],
    currentPage:1,
    pageSize: 5,
    total: 0,
    loading: true,
    spreadTable:[],

    // tab 属性
    tagType:1,
    normIndex:['夏普比率:','詹森测度α:','特雷诺比率:','信息比率:'],
    tagList : [{name:'股票',key:0,type:'groupTag'},{name:'债券',key: 1,type:'groupTag'},{name:'期货',key: 2,type:'groupTag'},
        {name:'开放式基金',key: 3,type:'groupTag'}
    ],
    indicatorList : [{name:'股票',key:0,type: 'indicatorTag'},{name:'期货',key: 1,type: 'indicatorTag'},{name:'债券',key: 2,type: 'indicatorTag'},
        {name:'总计',key: 3,type: 'indicatorTag'}],
    // charts 属性
    option:{},
    heightCharts: 300,
}


