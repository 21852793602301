<template>
  <div>
    <el-card class="box-card">
      <mini-tag :tagList="tagList" @tagChange="tagChange"></mini-tag>
      <div>
        <div style="margin-bottom: 20px;float: left;"><i class="card-line"></i>业绩报告</div>
        <div v-for="(item ,index) in reportList" :key="item.key" @click="tabBtn(item.router)" style="float: right;"><el-button v-if="item.name != '业绩报告'" size="mini" :type="item.name == '新建'?'warning':'primary'" style="margin-right: 10px;"> {{ item.name }} </el-button></div>
      </div>
      <!--      <button @click="abc">aa</button>-->
      <spread-table :smallType="true" v-loading="loading" :pageSize="pageSize" :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="tableList" :tableData="spreadTable"></spread-table>
    </el-card>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col >
        <el-card class="box-card">
          <div style="margin-bottom: 10px" class="box-title">
            <div class="card-line"></div>指标
          </div>
          <div style="padding: 20px;margin-top: 30px">
            <el-row :gutter="20" class="row-norm" v-for="(item,index) in normIndex" :key="index">
              <el-col class="title-norm" :span="10">{{item}}</el-col>
              <el-col class="value-norm" :span="14">{{ normItem[index] }}</el-col>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card">
          <div style="margin-bottom: 10px"><i class="card-line"></i>资产走势</div>
          <!--          <mini-tag :tagList="indicatorList" @tagChange="tagChange"></mini-tag>-->
          <e-charts :heightCharts="heightCharts" :option="option"></e-charts>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import SpreadTable from '@/components/SpreadTable'
import MiniTag from '@/components/MiniTag'
import { groupData } from './groupData'
import ECharts from '@/components/Echarts'
import { tagChanges, getGroupTables, trendCharts, groupIndexs, sizeChanges, currentChanges } from './groupMethods'
import axios from "@/axios/globalaxios";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    MiniTag,
    ECharts,
  },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let data = reactive({
      reportList:[],
      // table 属性
      tableList: [
        { name: '证券代码', text: 'marketSID' },
        { name: '证券名称', text: 'name' },
        { name: '买卖方向', text: 'direction' },
        { name: '持仓数量', text: 'amount' },
        { name: '可用数量', text: 'availableAmount' },
        { name: '冻结数量', text: 'frozenAmount' },
        { name: '成本价', text: 'averageOpenPrice' },
        { name: '市价', text: 'currentPrice' },
        { name: '市值', text: 'marketValue' },
        { name: '浮动盈亏', text: 'floatingPL' },
        { name: '昨结', text: 'yesterdaySettlement' },
        { name: '保证金', text: 'margin' },
        // {name:'盈亏比例',text: 'profitAndLossRatio'},
        { name: '交易市场', text: 'marketPlace' },
      ],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      loading: true,
      spreadTable: [],

      // tab 属性
      tagType: 1,
      normIndex: ['夏普比率:', '詹森测度α:', '特雷诺比率:', '信息比率:'],
      normItem: [],
      tagList: [{ name: '股票', key: 1, type: 'groupTag' }, { name: '债券', key: 2, type: 'groupTag' }, 
      { name: '期货', key: 3, type: 'groupTag' },
        { name: '开放式基金', key: 3, type: 'groupTag' },
        { name: '外汇', key: 4, type: 'groupTag'},
      ],
      indicatorList: [{ name: '股票', key: 0, type: 'indicatorTag' }, { name: '期货', key: 1, type: 'indicatorTag' }, { name: '债券', key: 2, type: 'indicatorTag' },
      { name: '总计', key: 3, type: 'indicatorTag' }],
      // charts 属性
      option: {},
      heightCharts: 300,
    })
    onMounted(() => {
      if(sessionStorage.getItem('reportList')){
        data.reportList=JSON.parse(sessionStorage.getItem('reportList'))
      }
      if (JSON.parse(sessionStorage.getItem('courseType')) !== 5) {
        let newList = data.tagList[JSON.parse(sessionStorage.getItem('courseType')) - 1]
        data.tagType = JSON.parse(sessionStorage.getItem('courseType'))

        newList.key = 0
        data.tagList = [newList]
      }
      getGroupTable()
      trendChart()
      groupIndex()
    })
    let tagChange = (value) => { tagChanges(value, data) }
    let getGroupTable = () => { getGroupTables(data) }  //  获取table数据
    let trendChart = () => { trendCharts(data) }  //  资产走势
    let groupIndex = () => { groupIndexs(data) }  //  指标
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let abc = () => {
      axios.post('/tradelab/competition/findKeyRatios', {
        "competitionId": "50000000450000",
        "matchType": 0,
        "isExport": true,
        "pageIndex": 1,
        "pageSize": 10
      }, { responseType: 'blob' }
      ).then(res => {
        let FileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1])
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          navigator.msSaveBlob(blob, FileName)
        } else {
          const elink = document.createElement('a');
          elink.download = FileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      }).catch(err => { })
    }
    const tabBtn=(url)=>{
      router.push(url)
    }
    return {
      ...toRefs(data),
      tagChange,
      sizeChange,
      currentChange,
      abc,
      tabBtn
    }
  }
})
</script>

<style scoped lang="less">
.box-card {
  /*height: 500px;*/
  // min-height: 450px;
  .row-norm {
    line-height: 50px;
    .title-norm {
      color: #000;
      text-align: right;
      font-size: 13px;
    }
    .value-norm {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}
</style>
